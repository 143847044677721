<template>
  <section class="invoice">
    <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
    <div v-else class="content-wrapper">
        <div class="page-header">
        <h3 class="page-title">
            Bon de commande
        </h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            <li class="breadcrumb-item">Mes Commandes</li>
            <li class="breadcrumb-item active" aria-current="page">facture</li>
            </ol>
        </nav>
        </div>
        <div v-if="notFound">
            <h3 class="text-center">Cette réservation a été annulée par le client</h3>
        </div>
        <div v-else class="row">
            <div class="col-lg-12">
                <div class="card px-2">
                    <div class="card-body">
                        <div class="container-fluid">
                        <h3 class="text-right my-5"> <p>Référence :</p> MEB-{{getIndex}}</h3>
                        <hr> </div>
                        <div class="container-fluid d-flex justify-content-between">
                        <div class="col-lg-3 pl-0">
                            <p class="mt-5 mb-2">
                            <b>Administrateur</b>
                            </p>
                            <p>Mon échappée Belle,
                            <br>64 avenue Saint Exupéry,
                            <br>81600 GAILLAC.</p>
                        </div>
                        <div class="col-lg-3 pr-0">
                            <p class="mt-5 mb-2 text-right">
                            <b>Réserver pour </b>
                            </p>
                            <p class="text-right" v-if="orders.deliveryinfos">{{orders.deliveryinfos.name}}
                            <br> <span v-if="orders.deliveryinfos.phone"> {{orders.deliveryinfos.phone}},</span>
                            <br> <span v-if="orders.deliveryinfos.Address">{{orders.deliveryinfos.Address}},</span> 
                            <br> <span v-if="orders.deliveryinfos.City">{{orders.deliveryinfos.City}},</span> 
                            <br> <span v-if="orders.deliveryinfos.Country">{{orders.deliveryinfos.Country}},</span> 
                            <br> <span v-if="orders.deliveryinfos['Zip code']">{{orders.deliveryinfos['Zip code']}}</span>
                            
                            <!-- informations dans le get/user -->
                            <br> <span v-if="user && !orders.deliveryinfos.phone"> {{user.tel}},</span>

                            </p>
                        </div>
                        </div>
                        <div class="container-fluid d-flex justify-content-between">
                            <div class="col-lg-3 pl-0">
                                <p class="mb-0 mt-5">Fait le: {{dateToFr2(orders.dateorder)}}</p>
                                <span>Pour le : <h4>{{dateToFr2(orders.purchases[0].datestart)}}</h4> </span>
                                <!-- <span>Pour le : <h4>{{dateToFr2(orders.purchases[0].datestart)}}</h4> </span> -->
                            </div>
                            <div>
                                <!-- <button class="btn btn-primary"  @click="editReservation(ulid)" v-if="orders.purchases && orders.purchases[0].service != null">MODIFIER CE RENDEZ VOUS</button> -->
                                <button class="btn btn-primary" @click="signal()">MODIFIER CE RENDEZ VOUS</button>
                            </div>
                        </div>
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-if="orders.purchases && orders.purchases[0].service != null">
                            <!-- tableau réservation -->
                            <div class="table-responsive w-100">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                    <th>#</th>
                                    <th>Description</th>
                                    <th class="text-left">Date</th>
                                    <th class="text-left">Durée</th>
                                    <th class="text-left">Débute à</th>
                                    <th class="text-left">Se termine à</th>
                                    <th class="text-right">Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                    <td class="text-left">{{purchaseId+1}}</td>
                                    <td class="text-left">{{purchase.service.name}}</td>
                                    <td class="text-left">{{today(purchase.datestart)}}</td>
                                    <td class="text-left">{{calculTimes(purchase.service.duration)}}</td>
                                    <td class="text-left">{{toTime(purchase.datestart)}}</td>
                                    <td class="text-left">{{toTime(purchase.dateend)}}</td>
                                    <td class="text-right"> ${{purchase.service.price}} </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- tableau d'achat -->
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-else-if="orders.purchases && orders.purchases[0].product != null">
                            <div class="table-responsive">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                    <th>#</th>
                                    <th>Description</th>
                                    <th class="text-left">Prix unitaire</th>
                                    <th class="text-left">Quantité achetée</th>
                                    <th class="text-left">Prix total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                    <td>{{purchaseId + 1}}</td>
                                    <td class="text-left">{{purchase.product.name}}</td>
                                    <td class="text-left">{{purchase.product.price *1.2}}€</td>
                                    <td class="text-left">{{purchase.quantity}}</td>
                                    <td class="text-left">{{purchase.quantity * (purchase.product.price *1.2) }}€</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- tableau de bon de d'achat (vouchers) -->
                        <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-else-if="orders.purchases && orders.purchases[0].voucher != null">
                            <div class="table-responsive">
                                <table class="table" >
                                <thead>
                                    <tr class="bg-dark text-white">
                                        <th>#</th>
                                        <th>Envoyeur</th>
                                        <th class="text-left">Destinataire</th>
                                        <th class="text-left">Bon d'achat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-right" v-for="(purchase, purchaseId) in orders.purchases" :key="purchaseId">
                                        <td class="text-left">{{purchaseId + 1}}</td>
                                        <td class="text-left">{{sender}}</td>
                                        <td class="text-left">{{receiver}}</td>
                                        <td class="text-left">{{purchase.voucher.price}}€</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="container-fluid mt-5 w-100">
                        <h4 class="text-right mb-5">Total : ${{total}}</h4>
                        <hr> </div>
                        <div class="container-fluid w-100 d-flex justify-content-end" v-if="orders.purchases && orders.purchases[0].service != null">
                            <span v-if="ulid != '01FY46XE8PN27S18ZJYS2GEFKZ'"  class="btn btn-outline-danger float-left mt-4 ml-2" @click="DeleteOrder()" >
                                <i class="fa fa-trash"></i> ANNULER CE RENDEZ VOUS
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import pageLoader from '../../components/pageLoader.vue'
import { dateMixin } from '../mixins/date'
export default {
  name: 'ordersDetails',
  mixins:[dateMixin],
  components:{
        pageLoader
  },
  data () {
      return {
          ulid: '',
          orders: '',
          loadershow:true,
          allOrder:null,
          purchases: [],
          notFound:false,
          user:{}
      }
  },
  computed:{
      total: function(){
          var tota = 0;
          this.purchases.forEach(element => {
              if(element.service){
                  tota = tota + element.service.price
              }
              if(element.product){
                  tota = tota + (element.product.price * 1.2) * element.quantity
              }
              if(element.voucher){
                  tota = tota + element.voucher.price * element.quantity
              }
          }); 
          return tota
      },
      receiver(){
          if(this.orders.purchases[0].voucher){
              return this.orders.purchases[0].voucher.receiver.firstname +' '+ this.orders.purchases[0].voucher.receiver.lastname
          }else{
              return 'hi'
          }
      },
      sender(){
          if(this.orders.purchases[0].voucher){
              return this.orders.purchases[0].voucher.sender.firstname +' '+ this.orders.purchases[0].voucher.sender.lastname
          }else{
              return 'hi'
          }
      },
      getIndex(){
            let index = 0
            if (this.allOrder) {
                index = this.allOrder.findIndex( object => {
                    return object.ulid == this.ulid
                })
            }
            return index
      }


  },
  methods: {
    signal(){
        alert('Momentanément désactivé! Cette fonctionnalité sera de retour ce Lundi même, merci')
    },
      getOrdersDetails(ulid){
          axios.get(`orders/${ulid}`)
          .then(resGetOrdersDetails => {
            this.getUserDetails(resGetOrdersDetails.data.user.ulid)
            this.orders=resGetOrdersDetails.data
            this.purchases=resGetOrdersDetails.data.purchases
            this.loadershow =false
          })
          .catch(errOrdersDetails => {
            if(errOrdersDetails.response.status == 404){
                this.notFound = true
                this.loadershow =false
                return
            }
          })
      },
      validateOrder() {
          axios.post(`myorders/validate/${this.ulid}`,
          {}, {
              headers: {
                  'X-AUTH-TOKEN': localStorage.getItem('token')
              }
          })
          .then(resValidateOrder => console.log({resValidateOrder}))
          .catch(errValidateOrder => console.log(errValidateOrder))
      },
      calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            return timeString
        },
      toTime(time){
          return time.substr(11, 5)
      },
      getAllOrders() {
        axios.get('orders')
        .then(resOrders=> {
          {
            this.allOrder = resOrders.data.result
          }
        })
        .catch(errOrders=> console.log(errOrders)) 
        },
        details(record){
            this.ordersDetails(record.ulid)
      },
      today(time){
          return time.substr(0, 10)
      },
      DeleteOrder(){
          this.$swal({
                title: 'Voulez-vous annuler cette réservation?',
                showCancelButton: true,
                confirmButtonColor: '#000',
                cancelButtonColor: 'rgb(162, 162, 162)',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Fermer'
            })
            .then((result) => {
                if (result.value) {
                this.loadershow = true;
                axios.delete('orders/'+this.ulid)
                    .then(()=>{
                    this.$router.go(-1)
                    })
                    .catch()
                }else{
                    this.loadershow=false
                }
            })
      },
      editReservation(ulid){
        this.$router.push({
            name:"edit-reservation",
            params:{
                ulid
            }
        })
      },
      getUserDetails(ulid){
        axios.get(`api/users/${ulid}`,{
            headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
        })
        .then(resGetUser =>{
            console.log({resGetUser});
            this.user = resGetUser.data
            console.log("user", this.user);
        })
        .catch(errGetUserDetails => {
            console.error(errGetUserDetails)
        })
      }
  },
  mounted () {
      this.getOrdersDetails(this.ulid)
      this.getAllOrders()
  },
  created () {
      this.ulid= this.$route.params.ulid
  }
}
</script>
